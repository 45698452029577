.ml-auto {
    text-align: center;
}
.selecione {
    border: 2px solid #3f51b5;
}
@font-face {
  font-family: "Leanwork";
  font-weight: 300;
  src: url("/fonts/LEANWORK-LIGHT.OTF") format("opentype");
}

@font-face {
  font-family: "Leanwork";
  font-weight: bold;
  src: url("/fonts/LEANWORK-BOLD.OTF") format("opentype");
}

/* *,
body {
  font-family: "Leanwork";
} */

