@font-face {
  font-family: "Leanwork";
  font-weight: 300;
  src: url("/fonts/LEANWORK-LIGHT.OTF") format("opentype");
}

@font-face {
  font-family: "Leanwork";
  font-weight: bold;
  src: url("/fonts/LEANWORK-BOLD.OTF") format("opentype");
}

/* *,
body {
  font-family: "Leanwork";
} */
